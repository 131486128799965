@tailwind base;
@tailwind components;
@tailwind utilities;

.cursor_pointer {
  cursor: pointer;
}

.slickSlider {
  width: 96% !important;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 27px !important;
  line-height: 1;
  opacity: 0.75;
  color: #ffff !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slickSlider {
  transition: opacity 0.5s ease;
}

.slickSlider.inactive {
  opacity: 0.5;
  /* Lower opacity for inactive slides */
}

.slickSlider.active {
  opacity: 1;
  /* Full opacity for active slide */
}

.clientSayActiveSlide {
  padding: 15px;
}

.slickSliderBanner {
  width: 100% !important;
}

.slickSliderAboutUs {
  width: 100% !important;
}

.bannerSlick {
  .slick-prev {
    left: -50px !important;
  }
}

.latestAdditionSlick {
  .slick-list {
    height: 350px;
  }

  .slick-prev {
    z-index: 99;
    left: 85px;
  }

  .slick-next {
    z-index: 99;
    right: 90px;
  }
}

.slickSliderLatestAddition {

  .slick-next {
    right: -4px !important;
  }

  .slick-prev {
    z-index: 99;
    left: -30px !important;
  }
}

.chatway--trigger-container {
  position: fixed !important;
  left: 1270px !important;
}

@media (max-width: 640px) {
  .latestAdditionSlick {
    width: 100%;

    .slick-list {
      height: 350px;
    }

    .slick-next {
      z-index: 99;
      right: 20px;
    }

    .slick-prev {
      z-index: 99;
      left: 0;
    }
  }

  .bannerSlick {
    .slick-next {
      right: 20px;
      z-index: 99;
    }

    .slick-prev {
      z-index: 99;
      left: -7px !important;
    }
  }
}

.mediaSpendsSlick {
  .slick-list {
    height: 330px;
  }

  .slick-prev {
    z-index: 99;
    left: 10px;
  }

  .slick-next {
    z-index: 99;
    right: 35px;
  }
}

.blogCaseStudySlick {
  .slick-list {
    height: 380px;
  }

  .slick-prev {
    z-index: 99;
    left: 10px;
  }

  .slick-next {
    z-index: 99;
    right: 35px;
  }
}

.ourClientSay {
  .slick-prev {
    z-index: 99;
    left: 10px;
  }

  .slick-next {
    z-index: 99;
    right: 20px;
  }
}

.next-slick-arrow {
  position: absolute;
  top: 50%;
  right: -25px;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 1;
}

.next-slick-arrow.rotate-180 {
  left: -25px;
  right: auto;
  transform: rotate(180deg) translateY(-50%);
}

.imageSliderSpot {
  .slick-prev {
    left: -29px;
  }

  .slick-next {
    right: -19px;
  }

  .next-slick-arrow {
    position: absolute;
    top: 50%;
    right: -10px;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1;
  }
}

ol,
ul,
menu {
  list-style: disc;
}